// 获取列表
const getListURL = `${API_CONFIG.baseURL}orderSettleAction!list.action`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}orderSettle/exportOrderSettle`

// 新增
const createURL = `${API_CONFIG.shopBaseURL}xxx`
// 编辑
const editURL = `${API_CONFIG.shopBaseURL}xxx`
// 查询详情
const queryURL = `${API_CONFIG.shopBaseURL}xxx`
// 上传
const uploadURL = `${API_CONFIG.baseURL}uploadAction!upload.action?module=shopgoods`

// 获取图表地址的url
// const fetchChartURL = `${API_CONFIG.shopBaseURL}enterprise/getEnterpriseStatisticsUrl`
const fetchChartURL = `${API_CONFIG.shopBaseURL}davinciReport/getEnterpriseStatisticsUrl`

export {
  getListURL,
  exportListURL,
  createURL,
  editURL,
  queryURL,
  uploadURL,
  fetchChartURL
}
