<template>
  <custom-analysis-core />
</template>

<script>
import CustomAnalysisCore from './CustomAnalysisCore'
export default {
  name: 'CustomAnalysisPlatform',
  components: {
    CustomAnalysisCore
  }
}
</script>

<style lang="scss" scoped>
</style>
